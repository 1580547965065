import { AccessToken } from '@azure/identity';
import { useEffect, useState } from "react";
import { makeStyles, tokens } from "@fluentui/react-components";
import axios from "axios";
import { Communication } from "../components";
import { Auth } from "../utils/auth";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  page: {
    backgroundColor: tokens.colorBrandBackground2,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "100%",
    "@media (max-width: 450px)": {
      flexDirection: "column",
    }
  },
  body: {
    width: "100%",
    minHeight: "100%"
  }
});

type LicenseDetailsResponse = {
  value: {skuId: string}[]
}

export default function LicenseComparisonPage() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [licenses, setLicenses] = useState([] as string[]);

  useEffect(() => {
    loadLicenses()
      .then(result => {
        setLicenses(result.data.value.map(x => x.skuId));
        setLoaded(true);
      })
      .catch((e) => {
        if (e.code === "ConsentFailed" || e.code === "InternalError")
          navigate("../tab");
        else
          alert(e.message);
      });
  }, [navigate]);

  const loadLicenses = async() => {
    const credential = Auth.getCredential();
    let token: AccessToken;
    const scopes = ['User.Read'];
    try {
      token = await credential.getToken(scopes);
    }
    catch(err) {
      await credential.login(scopes);
      token = await credential.getToken(scopes);
    }

    return await axios.get<LicenseDetailsResponse>("https://graph.microsoft.com/v1.0/me/licenseDetails?$select=skuId", {
      headers: {
        Authorization: `Bearer ${token.token}`
      }
    });
  }
  
  const styles = useStyles();

  return (
    <div className={styles.page}>
      <Communication/>
      <div className={styles.body}>
        {loaded && <iframe 
          style={{ width: "100%", height: "100%", border: 0 }}
          title="License Comparison View"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-scripts allow-same-origin allow-downloads allow-top-navigation-to-custom-protocols"
          src={`https://o365hq.com/srv_identifier_iframe?${licenses.map(id => `guid%5b%5d=${id}`).join('&')}`}>
        </iframe>}
      </div>
    </div>
  );
}
