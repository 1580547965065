import { makeStyles, MessageBarGroup, tokens } from "@fluentui/react-components";
import { MessageInfo } from "../MessageContext";
import { NotificationBar } from "./NotificationBar";

const useStyles = makeStyles({
  messages: {
    position: "absolute",
    width: "100%"
  },
  page: {
    backgroundColor: tokens.colorBrandBackground2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%"
  }
});

export interface FormPageLayoutProps {
  children: any
  message: MessageInfo
  onDismiss: () => void
}

export const PageLayout = (props: FormPageLayoutProps) => {
  const styles = useStyles();

  return (
    <div>
      <MessageBarGroup className={styles.messages}>
        {props.message && <NotificationBar {...props.message} onDismiss={props.onDismiss}/>}
      </MessageBarGroup>
      <div className={styles.page}>
        {props.children}
      </div>
    </div>
  );
}