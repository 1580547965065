import axios from "axios";
import { Auth } from "../utils/auth";

type UserData = {
  displayName: string,
  userPrincipalName: string
}

// TODO: rewrite to use Graph API
export const getTenantUsers = async (): Promise<UserData[]> => {
  const token = await Auth.getToken([]);
  const result = await axios.get<UserData[]>("/users", {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
  return result.data;
}