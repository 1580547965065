import { useEffect, useState } from "react";
import {
  ComboboxProps,
  Label,
  makeStyles,
  shorthands,
  Spinner,
  Text,
  tokens
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ChevronRightRegular } from '@fluentui/react-icons';
import { useSearchParams } from "react-router-dom";
import { loadTenants } from "../../apis/CustometManagementApi";
import { getHistoryItems, getHistoryReport, getUserRole } from "../../apis/OffersApi";
import { DownloadReportButton, ResetButton } from "../../components/buttons";
import { HistoryFeedTable } from "../../components/HistoryFeedTable";
import { OfferStatusCombobox, SearchSelect, SelectOption } from "../../components/inputs";
import { HistoryItem, UserRole } from "../../models";

const useStyles = makeStyles({
  content: {
    minHeight: "100%",
    minWidth: "1515px",
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.padding("20px", "10px", "0px", "10px")
  },
  datePicker: {
	  width: '150px',
    display: "flex",
    flexDirection: "column"
	},
  selectFilter: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px"
	},
  filters: {
    display: 'flex',
	  flexDirection: 'row'
  },
	chevron: {
    ...shorthands.margin("auto", "0", "0", "0")
	},
  resetButton: {
    marginTop: 'auto',
    height: '32px'
  },
  export: {
    marginLeft: 'auto'
  }
});

export const HistoryFeedPage = () => {
  const [searchParams] = useSearchParams();

  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<UserRole>();
  const [tenants, setTenants] = useState<SelectOption[]>([]);
  const [historyItems, setHistoryItems] = useState<HistoryItem[]>();

  // Filters
  const [offerId, setOfferId] = useState<string>(searchParams.get("offerId"));

  let defaultFromDate: Date;
  if (searchParams.get("from")) {
    defaultFromDate = new Date(searchParams.get("from"));
  } else {
    defaultFromDate = new Date();
    defaultFromDate.setDate(defaultFromDate.getDate() - 30);
  }
	const [selectedFromDate, setSelectedFromDate] = useState<Date>(defaultFromDate);

  const defaultToDate = searchParams.get("to") ? new Date(searchParams.get("to")) : new Date();
  const [selectedToDate, setSelectedToDate] = useState<Date>(defaultToDate);

	const [selectedTenantId, setSelectedTenantId] = useState<string>(searchParams.get("tenantId"));

  const offetStatus = searchParams.get("status");
  const defaultSelectedStatuses = offetStatus ? [offetStatus] : [];
	const [selectedStatuses, setSelectedStatuses] = useState<string[]>(defaultSelectedStatuses);
  
  useEffect(() => {
    getUserRole()
      .then((result) => {
        setUserRole(result.data.role);
      })
      .catch((error) => {
        alert(error);
      });
  }, []);
  
  useEffect(() => {
    if (userRole !== "itpEngineer") return;

    loadTenants()
      .then((result) => {
        setTenants(result.data.map(tenant => ({ name: tenant.name, value: tenant.id })));
      })
      .catch((error) => {
        alert(error);
      });
  }, [userRole]);

  useEffect(() => {
    if (!userRole) return;

    getHistoryItems({
      userRole,
      offerId,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      tenantId: selectedTenantId,
      statuses: selectedStatuses
    })
      .then((result) => {
        setHistoryItems(result.data);
        setLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRole, selectedFromDate, selectedToDate, selectedTenantId, selectedStatuses, offerId]);

  // Events
  const onDownloadClick = (format: string) => {
    getHistoryReport({
      userRole,
      format,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      tenantId: selectedTenantId,
      statuses: selectedStatuses
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `${response.headers['content-disposition'].split('filename="')[1].split('";')[0]}`;
          link.setAttribute(
            "download",
            fileName
          );
          document.body.appendChild(link);
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
      });
  }
  
	const onSelectToDate = (date: Date | null | undefined) => {
		if (date) {
			setSelectedToDate(date);
		}
	};

	const onSelectFromDate = (date: Date | null | undefined) => {
		if (date) {
			setSelectedFromDate(date);
		}
	};

  const onStatusesSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
    setSelectedStatuses(data.selectedOptions);
  };

  const onResetClick = () => {
    setOfferId(null);
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    setSelectedFromDate(fromDate);
    setSelectedToDate(new Date());
    setSelectedTenantId(null);
    setSelectedStatuses([]);
  };

  const onFormatDate = (date?: Date): string => {
    return !date ? '' : date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'});
  };

  const styles = useStyles();

  if (!isLoaded) {
    return (
      <Spinner
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%"
        }} 
      />
    );
  }

  return (
    <div className={styles.content}>
      <Text size={500}>Specify History Feed date range:</Text>
      <div className={styles.filters}>
        <div className={styles.datePicker}>
          <Label weight="semibold">From</Label>
          <DatePicker
            initialPickerDate={selectedFromDate}
            value={selectedFromDate}
            onSelectDate={onSelectFromDate}
            formatDate={onFormatDate}
            maxDate={selectedToDate}
          />
        </div>
        <ChevronRightRegular className={styles.chevron} fontSize={32} />
        <div className={styles.datePicker}>
          <Label weight="semibold">To</Label>
          <DatePicker
            initialPickerDate={selectedToDate} 
            value={selectedToDate}
            onSelectDate={onSelectToDate}
            formatDate={onFormatDate}
            minDate={selectedFromDate}
            maxDate={new Date()}
          />
        </div>
        {userRole === "itpEngineer" && (
          <div className={styles.selectFilter}>
            <Label weight="semibold">Tenant name</Label>
            <SearchSelect
              clearable
              placeholder="Select a tenant"
              options={tenants}
              setSelectedOption={(option) => setSelectedTenantId(option)}
              selectedOption={selectedTenantId}/>
          </div>
        )}
        <div className={styles.selectFilter}>
          <Label weight="semibold">Request status</Label>
          <OfferStatusCombobox
            selectedOptions={selectedStatuses}
            onOptionSelect={onStatusesSelect}/>
        </div>
        <ResetButton
          className={styles.resetButton}
          onClick={onResetClick}/>
        <DownloadReportButton className={styles.export} onDownloadClick={onDownloadClick}/>
      </div>
      <HistoryFeedTable
        items={historyItems}
        mode={userRole}
      />
    </div>
  );
}