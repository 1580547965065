import { TeamsUserCredential } from "@microsoft/teamsfx";
import config from "../config";

export const Auth = {
  getCredential: () => {
    return new TeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });
  },
  getToken: async (scopes: string | string[]) => {
    const credential = Auth.getCredential();
    return await credential.getToken(scopes);
  }
}