// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Hub from "./pages/Hub";
import { ConsiderOfferPage, LicenseRequestPage, PrepareOfferPage }from "./pages/LicenseAssistant";
import LicenseComparisonPage from "./pages/LicenseComparison";
import { TeamsFxContext } from "./Context";
import config from "./config";
import "./AxiosConfig";
import { HistoryFeedPage } from "./pages/LicenseAssistant/HistoryFeed";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  const customTheme = themeString === "dark"
    ? teamsDarkTheme
    : themeString === "contrast"
    ? teamsHighContrastTheme
    : teamsLightTheme
  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          {
            ...customTheme,
            colorNeutralBackground3: "#BDBDE6",
            colorStatusDangerBackground1:"#F3D6D8",
            colorStatusWarningBackground1: "#F1D765"
          }
        }
        style={{ background: tokens.colorBrandBackground2 }}
      >
        <Router>
          {loading ? (
            <Spinner
              size="large"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100%"
              }} 
            />
          ) : (
            <Routes>
              <Route path="/tab" element={<Hub />} />
              <Route path="/license-comparison" element={<LicenseComparisonPage />} />
              <Route path="/license-order" element={<LicenseRequestPage />} />
              <Route path="/license-order/:id/prepare" element={<PrepareOfferPage />} />
              <Route path="/license-order/:id/consider" element={<ConsiderOfferPage />} />
              <Route path="/history-feed" element={<HistoryFeedPage />} />
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
