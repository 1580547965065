import { Button, ButtonProps } from "@fluentui/react-components";
import { OpenRegular } from '@fluentui/react-icons';

export const ExternalLinkButton = (props: ButtonProps) => {
  return (
    <Button
      appearance="transparent"
      icon={<OpenRegular />}
      {...props}
    />
  );
}
